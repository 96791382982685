











































import { Component, Vue } from "vue-property-decorator";
import InvoiceStatusCards from "@/components/dashboard/InvoiceStatusCards.vue";
import InvoicesByAssignmentChart from "@/components/dashboard/InvoicesByAssignmentChart.vue";
import InvoicesAgingChart from "@/components/dashboard/InvoicesAgingChart.vue";
import CurrencyBreakdownChart from "@/components/dashboard/CurrencyBreakdownChart.vue";
import AverageSubmissionsChart from "@/components/dashboard/AverageSubmissionsChart.vue";
import AverageSubmissionsChartLimited from "@/components/dashboard/AverageSubmissionsChartLimited.vue";
import DocumentsProcessedChart from "@/components/dashboard/DocumentsProcessedChart.vue";
import PassThroughRateChart from "@/components/dashboard/PassThroughRateChart.vue";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";

@Component({
  components: {
    "invoice-status-cards": InvoiceStatusCards,
    "invoices-assignment-chart": InvoicesByAssignmentChart,
    "invoices-aging-chart": InvoicesAgingChart,
    "currency-breakdown-chart": CurrencyBreakdownChart,
    "average-submissions-chart": AverageSubmissionsChart,
    "average-submissions-chart-limited": AverageSubmissionsChartLimited,
    "documents-processed-chart": DocumentsProcessedChart,
    "pass-through-rate-chart": PassThroughRateChart,
    "icon-tooltip": IconTooltip
  }
})
export default class Dashboard extends Vue {
  // feature flag determinations (reactive)
  private shouldShowInvoiceStatusCards: boolean = this.$launchDarkly.variation(
    "show-dashboard-status-cards",
    false
  );
  private shouldShowProcessingMetricsForManager: boolean = this.$launchDarkly.variation(
    "show-dashboard-metrics-for-manager",
    false
  );
  private shouldShowProcessingMetricsForProcessor: boolean = this.$launchDarkly.variation(
    "show-dashboard-metrics-for-processor",
    false
  );
}
