














import { Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { dashboardService } from "@/services/dashboard.service";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { documentsProcessedChartOptions } from "./common/documents-processd-chart-options";

@Component({
  components: {
    apexchart: VueApexCharts
  }
})
export default class PassThroughRateChart extends UserRolesMixin {
  // reactive class properties
  private isFetching = true;
  private data: any = {
    manual: 0,
    ascend: 0
  };
  private dataSeries: number[] = [this.data.manual, this.data.ascend];
  private chartOptions: any = documentsProcessedChartOptions(
    "total-documents-processed-passthrough",
    {
      enabled: true,
      marker: {
        show: false
      }
    }
  );

  // computed properties
  private get percentOfDocumentsAutoAdded() {
    if ((this.data?.manual ?? 0) !== 0 || (this.data?.ascend ?? 0) !== 0) {
      return (
        (this.data.ascend / (this.data.manual + this.data.ascend)) *
        100
      ).toFixed(2);
    } else {
      return "0";
    }
  }

  async created() {
    if (this.shouldMakeDataRequest) {
      // make API call for chart data
      const documentsProcessedData = await dashboardService.getProcessedSnapshot();
      // use Object.assign() to maintain reactivity of data object
      if (documentsProcessedData) {
        this.data = Object.assign({}, this.data, documentsProcessedData);
      }
      this.isFetching = false;
    }
  }

  updateChart() {
    // update data series displayed in chart
    this.dataSeries = [this.data.manual, this.data.ascend];

    // create custom tooltip displayed when hovering over a data point
    this.chartOptions = {
      ...this.chartOptions,
      tooltip: {
        ...this.chartOptions.tooltip,
        custom: (options: any) => {
          const selectedDataPoint =
            options.w.globals.seriesNames[options.seriesIndex];

          if (selectedDataPoint === "by AscendAP") {
            const processorMetrics = this.data.ascend;
            return `
              <div class="tooltip-title">
                <span>Processor</span>
                <span>Submissions</span>
              </div>
              <br>
              <ul class="tooltip-list-items">
                <li>
                  <span>AscendAP</span> <span>${processorMetrics}</span>
                </li>
              </ul>
            `;
          } else {
            const processorMetrics: {
              processorName: string;
              processorID: string;
              count: number;
            }[] = this.data.processors[0]?.apTeam ?? [];
            const processorMetricsArray = processorMetrics.map(
              (processor: any) => {
                const displayName =
                  (processor.processorName?.length ?? 0) > 0
                    ? processor.processorName
                    : processor.processorID;
                return `<li><span>${displayName}</span> <span>${processor.count}</span></li>`;
              }
            );
            return `
              <div class="tooltip-title">
                <span>Processor</span>
                <span>Submissions</span>
              </div>
              <br>
              <ul class="tooltip-list-items">
                ${processorMetricsArray.join(" ")}
              </ul>
            `;
          }
        }
      }
    };
  }

  @Watch("data", { deep: true })
  dataChanged() {
    if (this.data) {
      this.updateChart();
    }
  }
}
