














import { Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { dashboardService } from "@/services/dashboard.service";
import { averageSubmissionsChartOptions } from "@/components/dashboard/common/average-submissions-chart-options";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { utils } from "@/utils/okta-utils";

@Component({
  components: {
    apexchart: VueApexCharts
  }
})
export default class AverageSubmissionsChartLimited extends UserRolesMixin {
  private isFetching = false;
  // reactive class properties
  private data: any = {};
  private averageNumberOfDocuments: number = this.data?.average || 0;
  private dataSeries: any[] = [
    {
      name: "Amount of documents submitted by you",
      data: []
    }
  ];
  private chartOptions: any = {
    ...averageSubmissionsChartOptions,
    xaxis: {
      title: {
        text: "Week start date",
        style: {
          fontFamily: "'Roboto', sans serif",
          fontWeight: 400,
          fontSize: "16px"
        }
      },
      labels: {
        formatter: function(value: any) {
          if (value) {
            return utils.utcToLocalDateString(moment(value).toDate(), "L");
          }
        }
      }
    }
  };

  async created() {
    if (this.shouldMakeDataRequest) {
      // make API call for chart data
      this.isFetching = true;
      const documentSubmissionsChartData = await dashboardService.getWeeklyDocumentSubmissions(
        "individual"
      );

      // use Object.assign() to maintain reactivity of data object
      if (documentSubmissionsChartData) {
        this.data = Object.assign({}, this.data, documentSubmissionsChartData);
      }
      this.isFetching = false;
    }
  }

  updateChart() {
    this.averageNumberOfDocuments = this.data?.average || 0;
    this.dataSeries = [
      {
        name: "Number of documents submitted by you",
        data: this.data?.chartData ?? []
      }
    ];
  }

  @Watch("data", { deep: true })
  dataChanged() {
    this.updateChart();
  }
}
