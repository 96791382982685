










































































import { Component } from "vue-property-decorator";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import InvoiceStatusCard from "./InvoiceStatusCard.vue";
import { dashboardService } from "@/services/dashboard.service";
import UserRolesMixin from "@/mixins/UserRoles.vue";

@Component({
  components: {
    "icon-tooltip": IconTooltip,
    "invoice-status-card": InvoiceStatusCard
  }
})
export default class InvoiceStatusCards extends UserRolesMixin {
  private isFetching = true;
  private apiData: any = {};

  async created() {
    if (this.shouldMakeDataRequest) {
      // make API call for invoice snapshot data
      const statusSnapshotData = await dashboardService.getInvoiceStatusSnapshots();

      // use Object.assign() to maintain reactivity of apiData object
      if (statusSnapshotData) {
        this.apiData = Object.assign({}, this.apiData, statusSnapshotData);
      }
      this.isFetching = false;
    }
  }
}
