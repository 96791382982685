export const documentsProcessedChartOptions = (
  id: string,
  tooltipSettings: any
): any => {
  return {
    chart: {
      id: id,
      fontFamily: "'Roboto', sans-serif",
      toolbar: {
        show: true
      },
      parentHeightOffset: 0
    },
    colors: ["#7B7ECC", "#D26856"],
    dataLabels: {
      enabled: true,
      dropShadow: {
        enabled: false
      },
      formatter: function(value: number, options: any) {
        if (value) {
          return options.w.globals.series[options.seriesIndex].toLocaleString();
        }
      }
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        bottom: 0
      }
    },
    labels: ["by your team", "by AscendAP"],
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      markers: {
        width: 12,
        height: 12,
        radius: "50%"
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    noData: {
      text: "No data available",
      align: "center",
      style: {
        fontSize: "16px"
      }
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: false // whether to show name of each chart section
            },
            value: {
              show: false // whether to show values of individual chart sections
            },
            total: {
              show: false // whether to show total of all chart sections
            }
          }
        },
        expandOnClick: false
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: tooltipSettings
  };
};
