


















































import { Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import { dashboardService } from "@/services/dashboard.service";
import Constants from "@/shared/constants/invoice-aging-chart";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { simpleInvoiceBarChartOptions } from "./common/simple-invoice-bar-charts";

@Component({
  components: {
    apexchart: VueApexCharts,
    "icon-tooltip": IconTooltip
  }
})
export default class InvoicesByAssignmentChart extends UserRolesMixin {
  // computed properties
  private get chartTypeOptions() {
    return this.chartTypes.filter(
      (type: string) => type !== this.selectedChartType
    );
  }
  private get seriesName() {
    return this.selectedChartType === Constants.COUNT
      ? "Invoices"
      : Constants.VALUE;
  }
  private get vCardHeight() {
    return (this.data?.length ?? 0) * 2.5;
  }
  private get sanitizedData() {
    return this.data?.map((el, index) => {
      return {
        x: "x" in el ? el.x : `Unlabeled-${index}`,
        y: "y" in el ? el.y : 0
      };
    });
  }

  // reactive class properties
  private data: any[] = [];
  private isChartTypeMenuOpen = false;
  private chartTypes = [Constants.VALUE, Constants.COUNT];
  private selectedChartType: string = this.chartTypes[0];
  private dataSeries: any[] = [];
  private chartOptions: any = simpleInvoiceBarChartOptions(
    "invoices-by-assignment",
    {
      align: "left",
      maxWidth: "auto",
      style: {
        fontWeight: 700,
        fontSize: "16px",
        colors: "#1195BF"
      }
    },
    ["#68A35C", "#1195BF", "#6B8FEB", "#2211AA"]
  );

  async created() {
    if (this.shouldMakeDataRequest) {
      await this.handleChartTypeChange(Constants.COUNT);
    }
  }

  attachClickListenersToLabels() {
    // get the DOM elements of the chart labels
    const labels = document.querySelectorAll(
      ".apexcharts-text.apexcharts-yaxis-label"
    );

    // iterate over labels and add click listener to each
    labels.forEach((label: any) => {
      label.addEventListener("click", (event: any) => {
        // navigate to Your Invoices tab of Invoice List page, and filter by selected assignment
        this.$router.push({
          path: "/documents",
          query: {
            tab: "all",
            assignment: [event.target.innerHTML]
          }
        });
      });
    });
  }

  async handleChartTypeChange(chartType: string) {
    this.selectedChartType = chartType;

    // make API call for chart data based on type (Value, Count) selected
    if (chartType === Constants.VALUE) {
      this.data = await dashboardService.getInvoicesByAssignment(
        Constants.VALUE
      );
    } else {
      this.data = await dashboardService.getInvoicesByAssignment(
        Constants.COUNT
      );
    }
  }

  updateChart() {
    this.dataSeries = [
      {
        name: this.seriesName,
        data: this.sanitizedData
      }
    ];

    // format the data labels differently for Count and Value
    this.chartOptions =
      this.selectedChartType === Constants.VALUE
        ? {
            ...this.chartOptions,
            dataLabels: {
              ...this.chartOptions.dataLabels,
              formatter: function(value: number) {
                if (value) {
                  if (Number.isInteger(value)) {
                    return value.toFixed(2).toLocaleString();
                  } else {
                    return value.toLocaleString();
                  }
                }
              }
            }
          }
        : {
            ...this.chartOptions,
            dataLabels: {
              ...this.chartOptions.dataLabels,
              formatter: function(value: number) {
                if (value) {
                  return value.toLocaleString();
                }
              }
            }
          };
  }

  mounted() {
    // need to wait for dataSeries to be updated before attaching listeners
    this.updateChart();
    this.$nextTick(() => {
      this.attachClickListenersToLabels();
    });
  }

  @Watch("data", { deep: true })
  dataChanged() {
    if (this.data) {
      this.updateChart();
      this.$nextTick(() => {
        this.attachClickListenersToLabels();
      });
    }
  }
}
