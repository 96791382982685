
























import { Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import { dashboardService } from "@/services/dashboard.service";
import UserRolesMixin from "@/mixins/UserRoles.vue";

@Component({
  components: {
    apexchart: VueApexCharts,
    "icon-tooltip": IconTooltip
  }
})
export default class CurrencyBreakdownChart extends UserRolesMixin {
  // reactive class properties
  private isFetching = true;
  private data: { [key: string]: number } = {};
  private dataSeries: any[] = [];
  private chartOptions: any = {
    chart: {
      id: "currency-breakdown",
      fontFamily: "'Roboto', sans-serif",
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      parentHeightOffset: 0
    },
    grid: {
      show: false,
      padding: {
        top: 20
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "10%"
      }
    },
    xaxis: {
      categories: ["current"],
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "right",
      labels: {
        useSeriesColors: true
      },
      markers: {
        width: 0,
        height: 0
      },
      onItemClick: {
        toggleDataSeries: false
      },
      formatter: function(seriesName: any, opts: any) {
        return [
          "<div class='d-flex flex-column mr-5'>",
          "<span class='legend-title'>",
          seriesName,
          "</span><span class='legend-subtitle'>",
          parseFloat(opts.w.globals.series[opts.seriesIndex][0])
            .toFixed(2)
            .toLocaleString(),
          "</span>",
          "</div>"
        ];
      }
    },
    tooltip: {
      enabled: false
    },
    colors: [
      "#78B493",
      "#8878B4",
      "#872A8F",
      "#8E9A04",
      "#D47773",
      "#031766",
      "#0BC095",
      "#D7837E",
      "#3C4D88",
      "#969696",
      "#299BB5",
      "#A076F9",
      "#3D3D3D",
      "#E659E8"
    ],
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    noData: {
      text: "No data available",
      align: "center",
      style: {
        fontSize: "16px"
      }
    }
  };

  updateChart() {
    this.dataSeries = Object.keys(this.data).map((key: string) => {
      return {
        name: key,
        data: [this.data[key]]
      };
    });
  }

  async created() {
    if (this.shouldMakeDataRequest) {
      const currencyBreakdownData = await dashboardService.getCurrencyBreakdownData();
      // use Object.assign() to maintain reactivity of data object
      if (currencyBreakdownData) {
        this.data = Object.assign({}, this.data, currencyBreakdownData);
      }
      this.isFetching = false;
    }
  }

  mounted() {
    this.updateChart();
  }

  @Watch("data", { deep: true })
  dataChanged() {
    this.updateChart();
  }
}
