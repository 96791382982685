export const averageSubmissionsChartOptions: any = {
  chart: {
    id: "average-document-submissions",
    fontFamily: "'Roboto', sans-serif",
    toolbar: {
      show: true
    },
    parentHeightOffset: 0
  },
  colors: ["#1997B6"],
  dataLabels: {
    enabled: false
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    showForZeroSeries: true,
    position: "top",
    horizontalAlign: "left",
    markers: {
      width: 12,
      height: 12,
      radius: "50%"
    },
    onItemClick: {
      toggleDataSeries: false
    }
  },
  noData: {
    text: "No data available",
    align: "center",
    style: {
      fontSize: "16px"
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 14,
      columnWidth: "20%"
    }
  },
  states: {
    normal: {
      filter: {
        type: "none",
        value: 0
      }
    },
    hover: {
      filter: {
        type: "none",
        value: 0
      }
    },
    active: {
      filter: {
        type: "none",
        value: 0
      }
    }
  },
  tooltip: {
    enabled: false
  },
  xaxis: {
    labels: {
      show: true
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  },
  yaxis: {
    title: {
      text: "Number of documents",
      style: {
        fontFamily: "'Roboto', sans serif",
        fontWeight: 400,
        fontSize: "16px"
      }
    }
  }
};
