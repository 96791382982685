export const simpleInvoiceBarChartOptions = (
  chartId: string,
  yAxisLabelOptions: any,
  barColors: string[]
) => {
  return {
    chart: {
      id: chartId,
      fontFamily: "'Roboto', sans-serif",
      toolbar: {
        show: true
      },
      parentHeightOffset: 0
    },
    grid: {
      show: false
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        borderRadius: 13,
        dataLabels: {
          position: "top"
        }
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      offsetX: 40,
      style: {
        colors: ["#213A49"],
        fontWeight: 400
      },
      formatter: function(value: number) {
        if (value) {
          return parseFloat(value.toFixed(2)).toLocaleString();
        }
      }
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      labels: yAxisLabelOptions
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: false
    },
    colors: barColors,
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    noData: {
      text: "No data available",
      align: "center",
      style: {
        fontSize: "16px"
      }
    }
  };
};
