
















import { Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { dashboardService } from "@/services/dashboard.service";
import { averageSubmissionsChartOptions } from "@/components/dashboard/common/average-submissions-chart-options";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { utils } from "@/utils/okta-utils";

@Component({
  components: {
    apexchart: VueApexCharts
  }
})
export default class AverageSubmissionsChart extends UserRolesMixin {
  // reactive class properties
  private isFetching = true;
  private data: any = {};
  private averageNumberOfDocuments: number = this.data?.average || 0;
  private dataSeries: any[] = [
    {
      name: "Amount of documents submitted by your team",
      data: []
    }
  ];
  private chartOptions: any = {
    ...averageSubmissionsChartOptions,
    tooltip: {
      enabled: true,
      marker: {
        show: false
      }
    },
    xaxis: {
      title: {
        text: "Week start date",
        style: {
          fontFamily: "'Roboto', sans serif",
          fontWeight: 400,
          fontSize: "16px"
        }
      },
      labels: {
        formatter: function(value: any) {
          if (value) {
            return utils.utcToLocalDateString(moment(value).toDate(), "L");
          }
        }
      }
    }
  };

  async created() {
    if (this.shouldMakeDataRequest) {
      // make API call for chart data
      const documentSubmissionsChartData = await dashboardService.getWeeklyDocumentSubmissions(
        "team"
      );

      // use Object.assign() to maintain reactivity of data object
      if (documentSubmissionsChartData) {
        this.data = Object.assign({}, this.data, documentSubmissionsChartData);
      }
      this.isFetching = false;
    }
  }

  updateChart() {
    this.averageNumberOfDocuments = this.data?.average || 0;

    // update data series displayed in chart
    this.dataSeries = [
      {
        name: "Number of documents submitted by your team",
        data: this.data?.chartData ?? []
      }
    ];

    // create custom tooltip displayed when hovering over a data point
    this.chartOptions = {
      ...this.chartOptions,
      tooltip: {
        ...this.chartOptions.tooltip,
        custom: (options: any) => {
          const week = options.w.globals.seriesX[0][options.dataPointIndex];
          const processorMetrics: any = this.data?.processors[week];
          const processorMetricsArray = Object.keys(processorMetrics).map(
            (processor: string) => {
              return `<li><span>${processor}</span> <span>${processorMetrics[processor]}</span></li>`;
            }
          );
          return `
            <div class="tooltip-title">
              <span>Processor</span>
              <span>Submissions</span>
            </div>
            <br>
            <ul class="tooltip-list-items">
              ${processorMetricsArray.join(" ")}
            </ul>
          `;
        }
      }
    };
  }

  @Watch("data", { deep: true })
  dataChanged() {
    this.updateChart();
  }
}
