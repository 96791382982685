














import { Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { dashboardService } from "@/services/dashboard.service";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { documentsProcessedChartOptions } from "./common/documents-processd-chart-options";

@Component({
  components: {
    apexchart: VueApexCharts
  }
})
export default class DocumentsProcessedChart extends UserRolesMixin {
  // reactive class properties
  private isFetching = false;
  private data: any = {
    manual: 0,
    ascend: 0
  };
  private dataSeries: number[] = [
    this.data?.manual ?? 0,
    this.data?.ascend ?? 0
  ];
  private chartOptions: any = documentsProcessedChartOptions(
    "total-documents-processed",
    {
      enabled: false
    }
  );

  // computed properties
  private get totalNumberOfDocuments() {
    return (
      ((this.data?.manual ?? 0) + (this.data?.ascend ?? 0)).toLocaleString() ||
      0
    );
  }

  async created() {
    if (this.shouldMakeDataRequest) {
      // make API call for chart data
      this.isFetching = true;
      const documentsProcessedData = await dashboardService.getProcessedSnapshot();
      // use Object.assign() to maintain reactivity of data object
      if (documentsProcessedData) {
        this.data = Object.assign({}, this.data, documentsProcessedData);
      }
    }
  }

  updateChart() {
    // update dataSeries
    this.dataSeries = [this.data?.manual ?? 0, this.data?.ascend ?? 0];
    this.isFetching = false;
  }

  @Watch("data", { deep: true })
  dataChanged() {
    this.updateChart();
  }
}
