
















import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class InvoiceStatusCard extends Vue {
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() data!: number;
  @Prop() tabReference!: string;
  @Prop() classname?: string;
  @Prop({ default: false }) isFetching?: boolean;
  @Prop({ default: "" }) cardClass!: string;

  handleClick(tabReference: string) {
    checkCurrentRouteAndRedirect(
      this.$router,
      `/documents?tab=${tabReference}`
    );
  }
}
