

























































































import { Component, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import Constants from "@/shared/constants/invoice-aging-chart";
import { dashboardService } from "@/services/dashboard.service";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { simpleInvoiceBarChartOptions } from "./common/simple-invoice-bar-charts";

@Component({
  components: {
    apexchart: VueApexCharts,
    "icon-tooltip": IconTooltip
  }
})
export default class InvoicesAgingChart extends UserRolesMixin {
  private isFetching = false;
  // computed properties
  private get chartTypeOptions() {
    return this.chartTypes.filter(
      (type: string) => type !== this.selectedChartType
    );
  }
  private get dateTypeOptions() {
    return this.dateTypes.filter(
      (type: string) => type !== this.selectedDateType
    );
  }
  private get seriesName() {
    return this.selectedChartType === Constants.COUNT ? "Invoices" : "Value";
  }

  // reactive class properties
  private data: any[] = [];
  private isChartTypeMenuOpen = false;
  private chartTypes: string[] = [Constants.VALUE, Constants.COUNT];
  private selectedChartType: string = this.chartTypes[1];

  private isDateTypeMenuOpen = false;
  private dateTypes: string[] = [Constants.DUE_DATE, Constants.ASCEND_DATE];
  private selectedDateType: string = this.dateTypes[0];

  private dataSeries: any[] = [];
  private chartOptions: any = simpleInvoiceBarChartOptions(
    "invoices-aging",
    {
      align: "left",
      style: {
        fontWeight: 400,
        fontSize: "16px"
      }
    },
    ["#D26856", "#E17460", "#B28E24", "#BC733E", "#949494"]
  );

  async handleChartTypeChange(chartType: string) {
    this.selectedChartType = chartType;
    // emit event and value to parent component
    await this.updateInvoiceAgingChart({
      chartType: chartType,
      dateType: this.selectedDateType
    });
  }

  async handleDateTypeChange(dateType: string) {
    this.selectedDateType = dateType;
    // emit event and value to parent component
    this.isFetching = true;
    await this.updateInvoiceAgingChart({
      chartType: this.selectedChartType,
      dateType: dateType
    });
    this.isFetching = false;
  }

  async updateInvoiceAgingChart(options: {
    chartType: string;
    dateType: string;
  }) {
    this.isFetching = true;
    if (
      options.chartType === Constants.COUNT &&
      options.dateType === Constants.DUE_DATE
    ) {
      this.data = await dashboardService.getInvoiceCountChartData("due");
    } else if (
      options.chartType === Constants.COUNT &&
      options.dateType === Constants.ASCEND_DATE
    ) {
      this.data = await dashboardService.getInvoiceCountChartData("ascend");
    } else if (
      options.chartType === Constants.VALUE &&
      options.dateType === Constants.DUE_DATE
    ) {
      this.data = await dashboardService.getInvoiceAmountChartData("due");
    } else if (
      options.chartType === Constants.VALUE &&
      options.dateType === Constants.ASCEND_DATE
    ) {
      this.data = await dashboardService.getInvoiceAmountChartData("ascend");
    }
    this.isFetching = false;
  }

  updateChart() {
    this.dataSeries = [
      {
        name: this.seriesName,
        data: this.data
      }
    ];

    // format the data labels differently for Count and Value
    // format the colors differently based on dateType
    if (
      this.selectedChartType === Constants.VALUE &&
      this.selectedDateType === Constants.DUE_DATE
    ) {
      this.chartOptions = {
        ...this.chartOptions,
        dataLabels: {
          ...this.chartOptions.dataLabels,
          formatter: function(value: number) {
            if (value) {
              return value.toFixed(2).toLocaleString();
            }
          }
        },
        colors: ["#D26856", "#E17460", "#B28E24", "#BC733E", "#949494"]
      };
    } else if (
      this.selectedChartType === Constants.COUNT &&
      this.selectedDateType === Constants.DUE_DATE
    ) {
      this.chartOptions = {
        ...this.chartOptions,
        dataLabels: {
          ...this.chartOptions.dataLabels,
          formatter: function(value: number) {
            if (value) {
              return value.toLocaleString();
            }
          }
        },
        colors: ["#D26856", "#E17460", "#B28E24", "#BC733E", "#949494"]
      };
    } else if (
      this.selectedChartType === Constants.VALUE &&
      this.selectedDateType === Constants.ASCEND_DATE
    ) {
      this.chartOptions = {
        ...this.chartOptions,
        dataLabels: {
          ...this.chartOptions.dataLabels,
          formatter: function(value: number) {
            if (value) {
              return parseFloat(value.toFixed(2)).toLocaleString();
            }
          }
        },
        colors: ["#BC733E", "#B28E24", "#E17460", "#D26856"]
      };
    } else if (
      this.selectedChartType === Constants.COUNT &&
      this.selectedDateType === Constants.ASCEND_DATE
    ) {
      this.chartOptions = {
        ...this.chartOptions,
        dataLabels: {
          ...this.chartOptions.dataLabels,
          formatter: function(value: number) {
            if (value) {
              return value.toLocaleString();
            }
          }
        },
        colors: ["#BC733E", "#B28E24", "#E17460", "#D26856"]
      };
    }
  }

  async created() {
    if (this.shouldMakeDataRequest) {
      this.isFetching = true;
      await this.updateInvoiceAgingChart({
        chartType: Constants.COUNT,
        dateType: Constants.DUE_DATE
      });
      this.isFetching = false;
    }
  }

  mounted() {
    this.updateChart();
  }

  @Watch("data", { deep: true })
  dataChanged() {
    if (this.data) {
      this.updateChart();
    }
  }
}
