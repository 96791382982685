import { app } from "@/main";

interface Currency {
  currency: string;
  amount: string;
}

interface InvoiceAmountChartData {
  dateGroup: string;
  amount: number;
}

interface InvoiceCountChartData {
  dateGroup: string;
  count: number;
}

class DashboardService {
  public async getCurrencyBreakdownData() {
    const response = await app.$ravenapi.get("api/dashboard/currencySnapshot");

    const currencyBreakdownData = response?.data?.reduce(
      (acc: [Currency], item: Currency) => {
        // If currency is empty string or json null type, call it "Unknown"
        if (!item.currency) {
          item.currency = "Unknown";
        }
        acc[item.currency] = item.amount;
        return acc;
      },
      {}
    );
    return currencyBreakdownData;
  }

  public async getInvoiceAmountChartData(dateType: string) {
    const response = await app.$ravenapi.get(
      `api/dashboard/invoicesAmount/?dateType=${dateType}`
    );

    const invoiceAmountChartData = response?.data?.map(
      (iacd: InvoiceAmountChartData) => ({
        x: iacd.dateGroup,
        y: iacd.amount || 0
      })
    );
    return invoiceAmountChartData;
  }

  public async getInvoiceCountChartData(dateType: string) {
    const response = await app.$ravenapi.get(
      `api/dashboard/invoicesCount/?dateType=${dateType}`
    );

    const invoiceCountChartData = response?.data?.map(
      (iacd: InvoiceCountChartData) => ({
        x: iacd.dateGroup,
        y: iacd.count || 0
      })
    );
    return invoiceCountChartData;
  }

  public async getInvoiceStatusSnapshots() {
    const response = await app.$ravenapi.get("api/dashboard/statussnapshot");

    return response?.data?.data;
  }

  // valid filterType param values are "count" and "value"
  public async getInvoicesByAssignment(filterType: string) {
    const response = await app.$ravenapi.get(
      `api/invoices/getbyassignment?filterType=${filterType}`
    );

    return response?.data?.data;
  }

  public async getWeeklyDocumentSubmissions(chartType: string) {
    const response = await app.$ravenapi.get(
      `api/invoices/getweeklydocumentsubmissions?chartType=${chartType}`
    );

    return response?.data;
  }

  public async getProcessedSnapshot() {
    const response = await app.$ravenapi.get("api/dashboard/processedsnapshot");

    return response?.data;
  }
}

export const dashboardService = new DashboardService(); // singleton
